<template>
<div>
  <div class="container-fluid  pb-4">
      <div class="row pb-4 pt-4">
            <div class="col-md-6 px-5 pl-md-5 mt-5">
                <div class="ml-md-3 mt-2 pt-5">
                    <div class=" mt-5 pt-2">
                        <h1 class="text-left">¡Gracias por tu solicitud!</h1>
                    </div>
                    <p class="mt-3">En un lapso no mayor a 24 horas serás contactado por uno de nuestos agentes quien te ayudará con tu proceso de cotización.</p>
                    
                </div>
            </div>
          
          <div class="col-md-6 py-5 mt-5" >
           <img src="../../assets/gracias.png" alt="">
          </div>
      </div>
  </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      
      
    };
  },
  mounted() {
    console.log('gracias aqui estoy')
  },
  methods: {
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        throw error;
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        throw error;
      }
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>